import user from './user';
import sys from './sys';
import hardware from './hardware';
import demo from './demo'
import full103 from './full103'

export default{
  user,
  sys,
  hardware,
  demo,
  full103,
}
